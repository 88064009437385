import get from 'lodash/get';
import { useSelector } from 'react-redux';

// constants
import { paymentFormDataSource } from '@/utils/constants/formTypes';
import { textValues } from './constants';

// actions
import { applyCoupon } from '@/api';
import * as eventActions from '@/utils/analytics';

export default function WithCouponCodeBoxProps(Component) {
	const WrappedComponent = (props) => {
		const isMobile = useSelector((state) => state.common.isMobile);

		const [discountAmount, setDiscountAmount] = React.useState('');
		const [errorText, setErrorText] = React.useState('');
		const [couponCode, setCouponCode] = React.useState('');
		const [formOpen, setFormOpen] = React.useState(false);
		const [loading, setLoading] = React.useState(false);
		const [canProceedToPayment, setCanProceedToPayment] = React.useState(false);

		const handleChange = (data) => {
			props.handleChange && props.handleChange(data);
		};

		const handleFormOpen = () => {
			setFormOpen(true);

			eventActions.trackEvent(
				isMobile ? 'COUPON_CODE_FORM_SHOWN' : 'COUPON_CODE_MODAL_SHOWN',
				{
					...props.analyticsProps,
					eventLabel: textValues.couponFormTriggerText,
					eventAction: 'CLICKED',
					eventCategory: 'USER_EVENT',
				}
			);
		};

		const handleFormClose = () => {
			eventActions.trackEvent(
				isMobile ? 'COUPON_CODE_FORM_CLOSED' : 'COUPON_CODE_MODAL_CLOSED',
				{
					...props.analyticsProps,
					eventLabel: isMobile ? textValues.closeFormText : 'CROSS_ICON',
					eventAction: 'CLICKED',
					eventCategory: 'USER_EVENT',
				}
			);

			setDiscountAmount('');
			setErrorText('');
			setCouponCode('');
			setLoading(false);
			setFormOpen(false);
		};

		const handleInputChange = (e) => {
			errorText && setErrorText('');
			setCouponCode(e.target.value);
			handleChange({
				value: e.target.value,
				source: paymentFormDataSource.couponCode,
			});
		};

		const handleKeyDown = (e) => {
			if (e.key !== 'Enter' || couponCode.trim().length === 0) return;
			e.preventDefault();
			handleApplyCouponCode();
		};

		const handleRenterCouponCode = () => {
			setCanProceedToPayment(false);
			setDiscountAmount('');
			setCouponCode('');
			handleChange({ value: '', source: paymentFormDataSource.couponCode });
			handleChange({ value: '', source: paymentFormDataSource.discountAmount });
		};

		const handleApplyCouponCode = () => {
			eventActions.trackEvent('BUTTON_CLICKED', {
				...props.analyticsProps,
				eventLabel: textValues.applyButtonText,
				eventAction: 'CLICKED',
				eventCategory: 'USER_EVENT',
			});

			setCanProceedToPayment(false);
			setLoading(true);

			applyCoupon({
				product_code: props.paymentDetail.productCode,
				coupon_code: couponCode.trim(),
			})
				.then((response) => {
					errorText && setErrorText('');
					setDiscountAmount(response.data.final_amount);
					handleChange({
						value: response.data.final_amount,
						source: paymentFormDataSource.discountAmount,
					});
					setCanProceedToPayment(true);
				})
				.catch((error) => {
					setErrorText(
						get(
							error,
							'response.data.error.message',
							textValues.applyCodeErrorText
						)
					);
				})
				.finally(() => setLoading(false));
		};

		React.useEffect(() => {
			return () => {
				setLoading(false);
				setCanProceedToPayment(false);
				setErrorText('');
				setCouponCode('');
				setDiscountAmount('');
				handleChange({ value: '', source: paymentFormDataSource.couponCode });
				handleChange({
					value: '',
					source: paymentFormDataSource.discountAmount,
				});
			};
		}, []);

		return (
			<Component
				{...props}
				// data
				couponCode={couponCode}
				discountAmount={(discountAmount || '').toString()}
				// function
				formOpen={formOpen}
				loading={loading}
				errorText={errorText}
				canProceedToPayment={canProceedToPayment}
				// handlers
				handleKeyDown={handleKeyDown}
				handleFormClose={handleFormClose}
				handleFormOpen={handleFormOpen}
				handleInputChange={handleInputChange}
				handleApplyCouponCode={handleApplyCouponCode}
				handleRenterCouponCode={handleRenterCouponCode}
			/>
		);
	};

	return WrappedComponent;
}
