import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
	formWrap: {
		border: props.noBackground ? '' : '1px solid #A8A8A8',
		borderRadius: props.noBackground ? '' : '15px',
		padding: theme.spacing(3),
		backgroundColor: props.noBackground ? '' : theme.palette.common.white,
	},
	form: {
		width: props.formWidth,
		margin: '0 auto',
	},
	titleWrap: {
		textAlign: 'center',
		width: '85%',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		},
	},
	title: {
		color: theme.palette.primary.main,
		...theme.typography.h5,
	},
	modalTitle: {
		color: theme.palette.primary.main,
		...theme.typography.h5,
	},
	descriptionWrap: {
		textAlign: 'center',
		width: '85%',
		margin: '0 auto',
	},
	description: {
		...theme.typography.body1,
		color: theme.palette.text.disabled,
	},
	modalDescription: {
		...theme.typography.body1,
		fontWeight: theme.typography.fontWeightSemiBold,
	},
	errorMessageWrap: {
		textAlign: 'center',
		margin: theme.spacing(1, 'auto'),
	},
	checkBoxLabel: {
		textAlign: 'left',
		display: 'flex',
	},
	checkBoxRoot: {
		margin: theme.spacing('auto', 0),
		justifyContent: 'center',
		width: '100%',
	},
	checkBoxIcon: {
		padding: theme.spacing(0),
		color: theme.palette.primary.main,
	},
	submitButtonWrap: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: props.formWidth || '100%',
		height: theme.spacing(6),
	},
	progress: {
		width: '100%',
	},
	submitButton: {
		borderRadius: props.isModal ? '0px' : '24px',
		padding: theme.spacing(1.5, 3),
		fontWeight: theme.typography.fontWeightSemiBold,
	},
}));

export default useStyles;
