import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	couponCodeWrap: {
		boxShadow: '1.52641px 2.28961px 20px rgba(245, 131, 32, 0.87)',
		borderRadius: '16px',
		width: '100%',
		padding: '24px 32px',
	},
	textWrap: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '24px',
		'& > * + *': {
			marginLeft: 'auto',
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			marginBottom: '16px',
			'& > * + *': {
				marginLeft: 0,
				marginTop: '16px',
			},
		},
	},
	couponFormTriggerText: {
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	inputTextLabelWrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	couponInputWrap: {
		display: 'flex',
		height: '48px',
		marginTop: '4px',
	},
	applyButton: {
		borderRadius: 'unset',
		borderTopRightRadius: '7px',
		borderBottomRightRadius: '7px',
		padding: '6px 24px',
		height: '46px',
		minWidth: '96px',
		[theme.breakpoints.down('sm')]: {
			padding: '6px 0',
			minWidth: '65px',
			fontSize: '16px',
		},
	},
}));

export const fieldSetSemiBorderStyle = {
	borderStyle: 'dashed',
	borderRight: 'none',
	borderTopLeftRadius: '7px',
	borderBottomLeftRadius: '7px',
};

export const fieldSetBorderStyle = {
	borderStyle: 'dashed',
	borderRadius: '7px',
};

export default useStyles;
