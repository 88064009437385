// api
import { getStateFromCity, submitFormData } from '@/api';
// constants
import { formResultKeys } from '@/components/shared/formBoxWrap/constants';
// components
import CouponCodeField from '@/components/widgets/couponCodeBox/field';
import NoSSRJsonForm from '@/components/widgets/form/NoSSRJsonForm';
// actions
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
	setUserRegistered,
} from '@/store/actions';
// utils
import * as eventActions from '@/utils/analytics';
import replaceSearchQueryValues from '@/utils/url/replaceSearchQueryValues';
import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';
import { getControlSchema, getDataWithCorrectTimeSlot } from './util';
// icons
import { CheckBoxOutlineBlank } from '@mui/icons-material';
// material
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	LinearProgress,
	Typography,
} from '@mui/material';
// Default
import fromPairs from 'lodash/fromPairs';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// styles
import useStyles from './style';

export default function FormBox({ trackEvent, ...props }) {
	const { classes } = useStyles(props.styleProps);
	const dispatch = useDispatch();

	const formErrors = React.useRef([]);
	const timeout = React.useRef(null);

	const isMobile = useSelector((state) => state.common.isMobile);
	const isTablet = useSelector((state) => state.common.isTablet);
	const location = useSelector((state) => state.router.location);

	const [formData, setFormData] = React.useState(props.defaultFormValues);
	const [controlSchema, setControlSchema] = React.useState(() => {
		if (has(props, 'controlSchema.properties.time_slot')) {
			return getControlSchema(props.controlSchema, formData.meeting_date);
		}

		return props.controlSchema;
	});
	const [showFormErrors, setShowFormErrors] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [whatsappContentChecked, setWhatsappConsentChecked] =
		React.useState(true);

	const uiSchema = React.useMemo(() => {
		return isMobile
			? props.mobileUiSchema
			: props.isModal
			? props.modalUiSchema
			: isTablet
			? props.tabletUiSchema
			: props.desktopUiSchema;
	}, [
		isMobile,
		isTablet,
		props.isModal,
		props.mobileUiSchema,
		props.modalUiSchema,
		props.tabletUiSchema,
		props.desktopUiSchema,
	]);

	const clearForm = () => {
		setFormData({});
	};

	// on form values update
	const onFormChange = ({ data, errors }) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		let _data, _controlSchema;

		if (
			has(controlSchema, 'properties.meeting_date') &&
			has(controlSchema, 'properties.time_slot') &&
			data.meeting_date &&
			data.meeting_date !== formData.meeting_date
		) {
			_controlSchema = getControlSchema(controlSchema, data.meeting_date);
			_data = getDataWithCorrectTimeSlot(data);
		}

		timeout.current = setTimeout(() => {
			props.onChangeHandler && props.onChangeHandler(data, errors);
			if (_controlSchema) setControlSchema(_controlSchema);
			setFormData(_data || data);
			formErrors.current = errors;
			console.log('errors', formErrors.current);
		}, 400);
	};

	// on form submit
	const onFormSubmit = async (e) => {
		e.preventDefault();
		setErrorMessage(null);
		setShowFormErrors(false);

		// track submit click
		trackEvent('FORM_SUBMIT_CLICKED', {
			eventLabel: props.formName,
			eventAction: 'CLICKED',
			eventCategory: 'USER_EVENT',
			subSection: props.submitButtonText,
		});

		// check form errors
		if (formErrors.current && formErrors.current.length > 0) {
			setShowFormErrors(true);
			console.log(
				"There are Errors in the Form, Can't submit. \n Errors are",
				formErrors.current
			);
			return;
		} else {
			if (props.handleExtraValidations) {
				// check custom form errors
				const { valid, message } = props.handleExtraValidations(formData);
				if (!valid) {
					setErrorMessage(message || 'There is an error in the form.');
					return;
				}
			}

			setLoading(true);
			let data = {};
			let search = new URLSearchParams(location.search);
			const locationParams = {
				...fromPairs([...search]),
				href: location.href,
				slug_href: location.pathname,
			};
			let whatsappOptIn = !props.disableWhatsappCheck
				? whatsappContentChecked
					? 'Opt-In'
					: 'Opt-Out'
				: null;

			if (formData.city && !formData.state) {
				const res = await getStateFromCity(formData.city);
				formData.state = res.data.name;
			}

			const formSubmitApiCall = props.submitApiAction || submitFormData;

			// structure custom or default payload for form submit api call
			if (props.formulateDataToBeSubmitted) {
				let extraData = {
					...props.constants,
					...props.pageConstants,
				};
				if (whatsappOptIn) {
					extraData.whatsapp_opt_in = whatsappOptIn;
				}

				data = props.formulateDataToBeSubmitted(
					formData,
					extraData,
					props,
					locationParams
				);
			} else {
				const _fair =
					formData.fair ||
					props.pageConstants.fair ||
					props.constants.fair ||
					'';

				let constantsByFair =
					props.pageConstants.constantsByFair ||
					props.constants.constantsByFair;
				constantsByFair =
					(isPlainObject(constantsByFair) && constantsByFair[_fair]) || {};

				data = {
					registrant: {
						...props.constants,
						...props.pageConstants,
						...formData,
						...constantsByFair,
					},
					utm_params: locationParams,
				};

				if (data.registrant.constantsByFair) {
					delete data.registrant.constantsByFair;
				}

				if (whatsappOptIn) {
					data.registrant.whatsapp_opt_in = whatsappOptIn;
				}
			}

			// initiate form submit api call
			formSubmitApiCall(props.submitLink, data)
				.then(async (result) => {
					// success callback to run before loading stops
					props.successCallback &&
						(await props.successCallback(result, data, {
							...props.pageConstants,
							...formData,
						}));

					setLoading(false);

					// track form submit success
					trackEvent('FORM_SUBMIT_SUCCESS', {
						eventLabel: props.formName,
						eventAction: 'FORM_SUBMIT_SUCCESS',
						eventCategory: 'LEAD',
						eventOrigin: 'WEBSITE',
					});

					eventActions.identify(data);
					dispatch(setUserRegistered(true));

					// notify parent component for success call
					props.handleResultCallback &&
						props.handleResultCallback(formResultKeys.success);
					clearForm();

					// redirect to link if present
					if (
						props.thankYouRedirectionLink &&
						!props.disableRedirectAfterSuccess
					) {
						const redirectionLink = replaceSearchQueryValues(
							props.thankYouRedirectionLink,
							{ ...props.pageConstants, ...formData }
						);

						if (isExternalUrl(redirectionLink)) {
							goToExternalRoute(redirectionLink);
						} else {
							dispatch(goToRoute(redirectionLink));
						}
					}
				})
				.catch(async (error) => {
					// error callback to run before loading stops
					props.errorCallback && (await props.errorCallback(error, data));

					setLoading(false);

					// track form submit failure
					trackEvent('FORM_SUBMIT_FAILED', {
						eventLabel: props.formName,
						eventAction: 'CLICKED',
						eventCategory: 'LEAD',
						eventOrigin: 'WEBSITE',
					});

					// notify parent component for error call
					props.handleResultCallback &&
						props.handleResultCallback(formResultKeys.error);
				});
		}
	};

	const renderFormRelatedData = () => {
		const FormComponent = props.formComponent || NoSSRJsonForm;

		return (
			<>
				{(props.title || props.description) && (
					<Box mb={2}>
						{props.title && (
							<Box className={classes.titleWrap}>
								<Typography
									component="h5"
									className={props.isModal ? classes.modalTitle : classes.title}
								>
									{props.title}
								</Typography>
							</Box>
						)}
						{props.description && (
							<Box className={classes.descriptionWrap}>
								<Typography
									component="p"
									className={
										props.isModal
											? classes.modalDescription
											: classes.description
									}
								>
									{props.description}
								</Typography>
							</Box>
						)}
					</Box>
				)}

				<Box
					className={props.isModal ? classes.modalFormWrap : classes.formWrap}
					my={props.title && !props.isModal && !props.noBackground ? 2 : 0}
				>
					{errorMessage && (
						<Box className={classes.errorMessageWrap}>
							<Typography color="error">{errorMessage} </Typography>
						</Box>
					)}
					<form
						onSubmit={onFormSubmit}
						noValidate
						autoComplete={
							location.pathname.startsWith('/kiosk/') ? 'off' : 'on'
						}
					>
						<Box className={classes.form}>
							<>
								<FormComponent
									uiSchema={uiSchema}
									schema={controlSchema}
									data={formData}
									onChange={onFormChange}
									readOnly={false}
									showFormErrors={showFormErrors}
									uploadConfig={props.uploadConfig}
								/>

								{props.couponCodeInput && (
									<Box mt={1} mb={3}>
										<CouponCodeField
											formFieldOnly={true}
											paymentDetail={props.paymentDetail}
											handleChange={props.couponCodeBoxChangHandler}
										/>
									</Box>
								)}

								{!props.disableWhatsappCheck && (
									<Box my={1.5}>
										<FormControlLabel
											classes={{
												label: classes.checkBoxLabel,
												root: classes.checkBoxRoot,
											}}
											control={
												<Checkbox
													checked={whatsappContentChecked}
													onChange={(event) =>
														setWhatsappConsentChecked(event.target.checked)
													}
													color="primary"
													icon={
														<CheckBoxOutlineBlank
															className={classes.checkBoxIcon}
															size="small"
														/>
													}
												/>
											}
											label={
												<Typography textAlign="left" variant="subtitle2">
													{props.whatsappContentText}
												</Typography>
											}
										/>
									</Box>
								)}
							</>
						</Box>
						<Box
							my={props.disableWhatsappCheck ? 1.5 : 0}
							className={classes.submitButtonWrap}
						>
							{loading ? (
								<LinearProgress className={classes.progress} />
							) : (
								<Button
									color="success"
									fullWidth={props.fullWidthSubmitButton}
									variant="contained"
									size="large"
									type="submit"
									className={classes.submitButton}
								>
									{props.submitButtonText}
								</Button>
							)}
						</Box>
					</form>
				</Box>
				{props.children}
			</>
		);
	};

	React.useEffect(() => {
		setFormData(props.defaultFormValues);
	}, [props.defaultFormValues]);

	React.useEffect(() => {
		if (has(props, 'controlSchema.properties.time_slot')) {
			setControlSchema(
				getControlSchema(props.controlSchema, formData.meeting_date)
			);
			return;
		}

		setControlSchema(props.controlSchema);
	}, [props.controlSchema]);

	return React.useMemo(renderFormRelatedData, [
		controlSchema,
		formData,
		showFormErrors,
		loading,
		errorMessage,
		whatsappContentChecked,
		props.children,
		uiSchema,
		props.submitButtonText,
	]);
}

FormBox.propTypes = {
	isModal: PropTypes.bool,
	// form UI
	title: PropTypes.string,

	description: PropTypes.string,
	submitButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	fullWidthSubmitButton: PropTypes.bool,
	styleProps: PropTypes.shape({
		noShadow: PropTypes.bool,
		formWidth: PropTypes.string,
	}),
	// form constants
	formName: PropTypes.string.isRequired,
	constants: PropTypes.object.isRequired,
	pageConstants: PropTypes.object,
	defaultFormValues: PropTypes.object,
	analyticsProps: PropTypes.object,
	disableWhatsappCheck: PropTypes.bool,
	whatsappContentText: PropTypes.string,
	// form funct
	formComponent: PropTypes.any,
	onChangeHandler: PropTypes.func,
	mobileUiSchema: PropTypes.object.isRequired,
	tabletUiSchema: PropTypes.object.isRequired,
	desktopUiSchema: PropTypes.object.isRequired,
	modalUiSchema: PropTypes.object,
	controlSchema: PropTypes.object.isRequired,
	// form submission
	submitLink: PropTypes.string.isRequired,
	submitApiAction: PropTypes.func,
	handleExtraValidations: PropTypes.func,
	formulateDataToBeSubmitted: PropTypes.func,
	successCallback: PropTypes.func,
	errorCallback: PropTypes.func,
	handleResultCallback: PropTypes.func,
	thankYouRedirectionLink: PropTypes.string,
	disableRedirectAfterSuccess: PropTypes.bool,
};

FormBox.defaultProps = {
	isModal: false,
	// form UI

	description: '',

	fullWidthSubmitButton: false,
	styleProps: {
		noShadow: true,
		formWidth: '100%',
	},
	// form constants
	constants: {},
	pageConstants: {},
	defaultFormValues: {},
	analyticsProps: {},
	disableWhatsappCheck: false,
	whatsappContentText: 'Get important information on Whatsapp',
	// form funct
	formComponent: null,
	modalUiSchema: {},
	// form submission
	thankYouRedirectionLink: '',
	disableRedirectAfterSuccess: false,
};
