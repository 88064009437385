export const textValues = {
	couponFormTriggerText: 'Promo Code?',
	couponCodeFieldText: 'Enter your promo code',
	closeFormText: 'Conitnue without code',
	renterCouponCodeText: 'Re-enter?',
	applyButtonText: 'Apply',
	proceedButtonText: 'Proceed',
	applyCodeErrorText: 'Failed to apply promo code!',
};

export const couponAppliedImage = {
	url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/coupon_applied_9dcd7764bf.gif',
	alternativeText: 'coupon applied',
};
