import PropTypes from 'prop-types';

// constants
import { couponAppliedImage, textValues } from './constants';

// components
import ImageComponent from '@/components/widgets/image';
import WithProps from './withProps';

// material
import {
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';

// styles
import useStyles, {
	fieldSetBorderStyle,
	fieldSetSemiBorderStyle,
} from './style';

const CouponCodeField = ({
	errorText,
	couponCode,
	loading,
	canProceedToPayment,
	...props
}) => {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.inputTextLabelWrap}>
				<Typography component="span" fontWeight="fontWeightMedium">
					{textValues.couponCodeFieldText}
				</Typography>
				{canProceedToPayment && (
					<Typography
						component="span"
						variant="subtitle2"
						fontWeight="fontWeightSemiBold"
						className={classes.couponFormTriggerText}
						onClick={props.handleRenterCouponCode}
					>
						{textValues.renterCouponCodeText}
					</Typography>
				)}
			</Box>
			<Box className={classes.couponInputWrap}>
				<TextField
					type="text"
					value={couponCode}
					onChange={props.handleInputChange}
					onKeyDown={props.handleKeyDown}
					disabled={canProceedToPayment}
					helperText={errorText || ''}
					FormHelperTextProps={{
						error: Boolean(errorText),
						disabled: !errorText,
						sx: { marginLeft: 0 },
					}}
					InputProps={{
						sx: { backgroundColor: '#f1f5fd' },
						endAdornment: canProceedToPayment && (
							<InputAdornment position="end">
								<ImageComponent
									src={couponAppliedImage.url}
									alt={couponAppliedImage.alternativeText}
									width="55px"
									height="55px"
									placeholder="empty"
									unoptimized={true}
								/>
							</InputAdornment>
						),
					}}
					sx={{
						width: '100%',
						fieldSet: canProceedToPayment
							? fieldSetBorderStyle
							: fieldSetSemiBorderStyle,
					}}
				/>
				{!canProceedToPayment && (
					<Button
						variant="contained"
						className={classes.applyButton}
						onClick={props.handleApplyCouponCode}
						disabled={loading || (couponCode || '').trim().length === 0}
					>
						{loading ? (
							<CircularProgress size="28px" />
						) : (
							textValues.applyButtonText
						)}
					</Button>
				)}
			</Box>
		</Box>
	);
};

export default WithProps(CouponCodeField);

CouponCodeField.propTypes = {
	// data
	couponCode: PropTypes.string,
	paymentDetail: PropTypes.object.isRequired,

	// handlers
	handleKeyDown: PropTypes.func.isRequired,
	handleInputChange: PropTypes.func.isRequired,
	handleApplyCouponCode: PropTypes.func.isRequired,
	handleRenterCouponCode: PropTypes.func.isRequired,

	// others
	loading: PropTypes.bool,
	canProceedToPayment: PropTypes.bool,
	analyticsProps: PropTypes.object,
};

CouponCodeField.defaultProps = {
	analyticsProps: {},
	loading: false,
	canProceedToPayment: false,
};
