import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-mini';

export const NO_TIMESLOT_TEXT = 'No Slots Available';
const interval = 30,
	firstSlotTime = '10:00',
	lastSlotTime = '18:30';

const timeSlots = (() => {
	const timeSlotsArray = [],
		start = moment(firstSlotTime, 'HH:mm'),
		end = moment(lastSlotTime, 'HH:mm');

	while (start.isSameOrBefore(end)) {
		timeSlotsArray.push(
			`${start.format('hh:mm')}-${start
				.add(interval, 'minutes')
				.format('hh:mm')}`
		);
	}

	return timeSlotsArray;
})();

export const timeSlotOptions = (date = moment()) => {
	const now = moment(),
		selectedDate = moment(date),
		start = moment(firstSlotTime, 'HH:mm'),
		end = moment(lastSlotTime, 'HH:mm');

	if (selectedDate.isBefore(now, 'day')) {
		return [NO_TIMESLOT_TEXT];
	}

	if (selectedDate.isAfter(now, 'day') || now.isBefore(start)) {
		return timeSlots.slice();
	}

	if (now.isSameOrAfter(end)) {
		return [NO_TIMESLOT_TEXT];
	}

	const currentHour = Number(now.format('hh'));

	let timeSlotHour, i;
	for (i = 0; i < timeSlots.length; i++) {
		timeSlotHour = Number(timeSlots[i].split('-')[0].split(':')[0]);
		if (timeSlotHour === currentHour) break;
	}

	return timeSlots.slice(Number(now.format('mm')) < interval ? i + 1 : i + 2);
};

export const getDataWithCorrectTimeSlot = (selectedData) => {
	if (!selectedData || !selectedData.time_slot || !selectedData.meeting_date) {
		return selectedData;
	}

	const data = cloneDeep(selectedData),
		now = moment(),
		selectedDate = moment(data.meeting_date);

	if (selectedDate.isBefore(now, 'day')) {
		data.time_slot = NO_TIMESLOT_TEXT;
	} else if (
		selectedDate.isAfter(now, 'day') &&
		data.time_slot === NO_TIMESLOT_TEXT
	) {
		data.time_slot = timeSlots[0];
	} else {
		const availableTimeSlots = timeSlotOptions(data.meeting_date);
		if (!availableTimeSlots.some((slots) => slots === data.time_slot)) {
			data.time_slot = availableTimeSlots[0];
		}
	}
	return data;
};
