import {
	getDataWithCorrectTimeSlot,
	NO_TIMESLOT_TEXT,
	timeSlotOptions,
} from '@/components/shared/formBoxWrap/util';
import cloneDeep from 'lodash/cloneDeep';

const getControlSchema = (controlSchema, date) => {
	const _controlSchema = cloneDeep(controlSchema);

	const timeSlotField = {
		type: 'string',
		enum: timeSlotOptions(date),
		not: { const: NO_TIMESLOT_TEXT },
	};

	_controlSchema.properties.time_slot = timeSlotField;

	return _controlSchema;
};

export { getDataWithCorrectTimeSlot, getControlSchema };
